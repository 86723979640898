import { EuiButtonIcon, htmlIdGenerator } from "@inscopix/ideas-eui";
import { addToast } from "components/GlobalToastList/GlobalToastList";
import { Tooltip } from "components/Tooltip/Tooltip";
import { useDataTableContext } from "pages/gdt/store/DataTableProvider";

export const QuickActionDownloadTableAsCsv = () => {
  const selectedTableId = useDataTableContext((s) => s.selectedTableId);

  // TODO: Implemented in downstream PR
  const handleClick = () => {
    addToast({
      id: htmlIdGenerator()(),
      title: "Not implemented",
      color: "warning",
      text: "Feature coming soon",
    });
  };

  return (
    <Tooltip content="Download table as CSV">
      <EuiButtonIcon
        aria-label="Download table as CSV"
        size="xs"
        iconType="download"
        onClick={handleClick}
        disabled={selectedTableId === undefined}
      />
    </Tooltip>
  );
};
