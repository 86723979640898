import { EuiLink } from "@inscopix/ideas-eui";
import assert from "assert";
import { NotificationsFeedBodyQuery } from "graphql/_Types";
import { useRoutes } from "hooks/useRoutes";
import { isNonNull } from "utils/isNonNull";
import { NotificationAlert } from "./NotificationAlert";
import { useCallback } from "react";
import { TRegion } from "providers/RegionsProvider";
import { isUndefined } from "lodash";
import { RegionBadge } from "components/RegionBadge/RegionBadge";
import { useFlyoutContext } from "providers/FlyoutProvider/FlyoutProvider";

interface NotificationAlertProjectSharedProps {
  data: NonNullable<
    NotificationsFeedBodyQuery["notifications"]
  >["nodes"][number] & { region?: TRegion };
  onNotificationRead: (
    notificationId: string,
    region?: TRegion,
    refetchNotifications?: () => void,
  ) => void;
  refetchNotifications: () => void;
}

/** Component that renders a notification that a project was shared with the user */
export const NotificationAlertProjectShared = ({
  data,
  onNotificationRead,
  refetchNotifications,
}: NotificationAlertProjectSharedProps) => {
  const { routeMap } = useRoutes();
  const { closeFlyout } = useFlyoutContext();

  const { dateCreated, hasSeen, id: notificationId } = data;
  const attrs = data.projectSharedNotification;
  assert(isNonNull(attrs));

  const onNotificationReadMemoized = useCallback(() => {
    if (!hasSeen) {
      onNotificationRead(notificationId, data.region, refetchNotifications);
    }
  }, [
    hasSeen,
    notificationId,
    onNotificationRead,
    refetchNotifications,
    data.region,
  ]);

  const { project, sharer } = attrs;
  if (project === null) {
    // bail out if we don't have access to this project
    return null;
  }
  const projectKey = project.key;

  const tenantKey = project.tenant?.key;
  assert(tenantKey !== undefined);

  const projectPath = routeMap["PROJECT"].dynamicPath({
    projectKey,
    tenantKey: tenantKey,
  }).path;

  const action = (() => {
    const handleClick = () => {
      closeFlyout();
      onNotificationReadMemoized();
    };

    return isUndefined(data.region) ? (
      <EuiLink to={projectPath} onClick={() => void handleClick()}>
        Go to project
      </EuiLink>
    ) : (
      <EuiLink
        href={`${data.region.url}${projectPath}`}
        target="_blank"
        onClick={() => void handleClick()}
      >
        Go to project <RegionBadge regionKey={data.region.key} />
      </EuiLink>
    );
  })();

  return (
    <NotificationAlert
      dateCreated={dateCreated}
      hasSeen={hasSeen}
      title="Project shared"
      onNotificationRead={onNotificationReadMemoized}
      text={
        <span>
          You have been granted access to <strong>{project.name}</strong>{" "}
          {sharer !== null ? (
            <>
              by{" "}
              <strong>
                {sharer.firstName} {sharer.lastName}
              </strong>
            </>
          ) : null}
          .
        </span>
      }
      action={action}
    />
  );
};
