/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { MenuBarMenu } from "components/MenuBar/MenuBarMenu";
import { MenuBar } from "components/MenuBar/MenuBar";
import { ModalCreateRows } from "../modals/ModalCreateRows";
import { ModalCreateColumn } from "../modals/ModalCreateColumn/ModalCreateColumn";
import { useDataTableContext } from "../store/DataTableProvider";
import { ModalDeleteRows } from "../modals/ModalDeleteRows";
import { ModalDeleteTable } from "../modals/ModalDeleteTable";
import { isDefined } from "utils/isDefined";

type Menu = "file" | "edit" | "view" | "insert";

type Modal = "create_column" | "create_rows" | "delete_rows" | "delete_table";

/**
 * Component that renders a menu bar for triggering actions on a data table or
 * analysis table
 */
export const DataTableMenuBar = () => {
  const selectedTable = useDataTableContext((s) => {
    return s.tables.find(({ id }) => id === s.selectedTableId);
  });
  const selectedRowIds = useDataTableContext((s) => s.selectedRowIds);
  const [selectedMenu, setSelectedMenu] = useState<Menu>();
  const [visibleModal, setVisibleModal] = useState<Modal>();
  const isAnyMenuOpen = selectedMenu !== undefined;
  const closeMenu = () => setSelectedMenu(undefined);

  return (
    <>
      {visibleModal === "create_column" && (
        <ModalCreateColumn onClose={() => setVisibleModal(undefined)} />
      )}
      {visibleModal === "create_rows" && (
        <ModalCreateRows onClose={() => setVisibleModal(undefined)} />
      )}
      {visibleModal === "delete_rows" && (
        <ModalDeleteRows onClose={() => setVisibleModal(undefined)} />
      )}
      {visibleModal === "delete_table" && isDefined(selectedTable) && (
        <ModalDeleteTable
          tableId={selectedTable.id}
          onClose={() => setVisibleModal(undefined)}
        />
      )}
      <MenuBar>
        <MenuBarMenu
          label="File"
          enableHoverToOpen={isAnyMenuOpen}
          isOpen={selectedMenu === "file"}
          items={[
            {
              type: "item",
              icon: "save",
              label: "Save version",
              onClick: () => undefined,
              disabled: true,
              requiredPermission: "edit",
            },
            {
              type: "item",
              icon: "download",
              label: "Download as CSV",
              onClick: () => undefined,
              disabled: true,
              requiredPermission: "view",
            },
            {
              type: "item",
              icon: "exportAction",
              label: "Export to DANDI",
              onClick: () => undefined,
              disabled: true,
              requiredPermission: "execute",
            },
          ]}
          onClose={closeMenu}
          onOpen={() => setSelectedMenu("file")}
        />
        <MenuBarMenu
          label="Edit"
          enableHoverToOpen={isAnyMenuOpen}
          isOpen={selectedMenu === "edit"}
          items={[
            {
              type: "item",
              icon: "copy",
              label: "Copy selected rows",
              onClick: () => undefined,
              disabled: true,
              requiredPermission: "view",
            },
            {
              key: "separator_1",
              type: "separator",
            },
            {
              type: "item",
              icon: "trash",
              label: "Delete table",
              onClick: () => setVisibleModal("delete_table"),
              disabled: selectedTable === undefined,
              requiredPermission: "edit",
            },
            {
              type: "item",
              icon: "trash",
              label: "Delete selected rows",
              onClick: () => setVisibleModal("delete_rows"),
              disabled: selectedRowIds.length === 0,
              requiredPermission: "edit",
            },
          ]}
          onClose={closeMenu}
          onOpen={() => setSelectedMenu("edit")}
        />
        <MenuBarMenu
          label="View"
          enableHoverToOpen={isAnyMenuOpen}
          isOpen={selectedMenu === "view"}
          items={[
            {
              type: "item",
              icon: "folderOpen",
              label: "Files",
              onClick: () => undefined,
              disabled: true,
              requiredPermission: "view",
            },
            {
              type: "item",
              icon: "notebookApp",
              label: "Notes",
              onClick: () => undefined,
              disabled: true,
              requiredPermission: "view",
            },
            {
              type: "item",
              icon: "timeRefresh",
              label: "Table versions",
              onClick: () => undefined,
              disabled: true,
              requiredPermission: "view",
            },
          ]}
          onClose={closeMenu}
          onOpen={() => setSelectedMenu("view")}
        />
        <MenuBarMenu
          label="Insert"
          enableHoverToOpen={isAnyMenuOpen}
          isOpen={selectedMenu === "insert"}
          items={[
            {
              type: "item",
              icon: "plus",
              label: "Insert row(s)",
              onClick: () => setVisibleModal("create_rows"),
              disabled: selectedTable === undefined,
              requiredPermission: "edit",
            },
            {
              type: "item",
              icon: "plus",
              label: "Insert column",
              onClick: () => setVisibleModal("create_column"),
              disabled:
                selectedTable === undefined || selectedTable.kind !== "data",
              requiredPermission: "edit",
            },
          ]}
          onClose={closeMenu}
          onOpen={() => setSelectedMenu("insert")}
        />
      </MenuBar>
    </>
  );
};
