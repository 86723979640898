import { ContextOutOfBoundsError } from "providers/ContextOutOfBoundsError";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

type FlyoutType = "default" | "notifications" | null;
type FlyoutContextType = {
  flyout: FlyoutType;
  setFlyout: (flyout: FlyoutType) => void;
  closeFlyout: () => void;
};

const FlyoutContext = createContext<FlyoutContextType | undefined>(undefined);

interface FlyoutProviderProps {
  children: ReactNode;
}

/** Context provider for global app-level flyouts */
export const FlyoutProvider = ({ children }: FlyoutProviderProps) => {
  const [flyout, setFlyout] = useState<FlyoutType>("default");

  const closeFlyout = useCallback(() => {
    setFlyout(null);
  }, []);

  return (
    <FlyoutContext.Provider value={{ flyout, setFlyout, closeFlyout }}>
      {children}
    </FlyoutContext.Provider>
  );
};

/** Hook for consuming the {@link FlyoutContext} */
export const useFlyoutContext = () => {
  const context = useContext(FlyoutContext);
  if (!context) {
    throw new ContextOutOfBoundsError("FlyoutContext");
  }
  return context;
};
