import {
  EuiFlyout,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFlyoutBody,
  EuiHeaderSectionItemButton,
  EuiIcon,
  useEuiTheme,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSwitch,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import { NotificationsFeedBody } from "components/NotificationsFeed/NotificationsFeedBody";
import { useUpdateNotificationDjango } from "hooks/useUpdateNotificationDjango";
import { cloneDeep } from "lodash";
import { TRegion } from "providers/RegionsProvider";
import { useCallback, useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { updateCacheFragment } from "utils/cache-fragments";
import { isDefined } from "utils/isDefined";
import { useNotificationUnreadCount } from "./Notifications.hooks";
import { useFlyoutContext } from "providers/FlyoutProvider/FlyoutProvider";

export const Notifications = () => {
  const theme = useEuiTheme();
  const [onlyShowUnread, setOnlyShowUnread] = useState(false);
  const { updateNotification } = useUpdateNotificationDjango();
  const {
    unreadCount: unreadCountInternal,
    refetch: refetchUnreadCountInternal,
  } = useNotificationUnreadCount("internal");
  const {
    unreadCount: unreadCountExternal,
    refetch: refetchUnreadCountExternal,
  } = useNotificationUnreadCount("external");

  /** Event handler called when an unread notification is read */
  const handleNotificationRead = useCallback(
    async (
      notificationId: string,
      region?: TRegion,
      refetchNotifications?: () => void,
    ) => {
      // Update cache
      updateCacheFragment({
        __typename: "Notification",
        id: notificationId,
        update: (data) => {
          const newData = cloneDeep(data);
          newData.hasSeen = true;
          return newData;
        },
      });

      // Mark notification as read
      try {
        await updateNotification({
          id: notificationId,
          has_seen: true,
          region,
        });
      } catch (error) {
        captureException("Failed to mark notification as read", {
          extra: { notificationId },
        });
      }

      // Update local unread notification count
      void refetchUnreadCountInternal();
      void refetchNotifications?.();

      // Update external unread notification count and refetch external notifications
      if (isDefined(region)) {
        void refetchUnreadCountExternal();
      }
    },
    [
      refetchUnreadCountExternal,
      refetchUnreadCountInternal,
      updateNotification,
    ],
  );

  /**
   * Flyout visibility state:
   * - Flyout is visible by default on larger screens on homepage
   * - Flyout is hidden by default on smaller screens
   * - Flyout visibility is toggled by user action on all screens
   * - Flyout visibility is toggled by screen size if user has not closed it on larger screens
   */

  const match = useRouteMatch("/");
  const isHomePage = match?.isExact ?? false;

  const totalCount = unreadCountInternal + unreadCountExternal;
  const hasNotifications = totalCount !== 0;

  const { flyout, setFlyout, closeFlyout } = useFlyoutContext();
  const [isDefaultFlyoutVisible, setIsDefaultFlyoutVisible] = useState(false);

  useEffect(() => {
    const checkSize = () => {
      if (isHomePage) {
        if (window.innerWidth < theme.euiTheme.breakpoint.xxl) {
          setIsDefaultFlyoutVisible(false);
        } else if (hasNotifications) {
          setIsDefaultFlyoutVisible(true);
        }
      }
    };
    window.addEventListener("resize", checkSize);
    return () => window.removeEventListener("resize", checkSize);
  }, [
    isHomePage,
    match?.isExact,
    theme.euiTheme.breakpoint.xxl,
    hasNotifications,
  ]);

  useEffect(() => {
    setIsDefaultFlyoutVisible(
      isHomePage && window.innerWidth >= theme.euiTheme.breakpoint.xxl,
    );
  }, [isHomePage, theme.euiTheme.breakpoint.xxl]);

  const isFlyoutVisible =
    (isDefaultFlyoutVisible && flyout === "default") ||
    flyout === "notifications";

  const handleToggle = () => {
    // user controlled action unsets "default" and toggles between null and "notifications"
    // this replaces tracking the user interaction state as a separate state
    // as the only way the flyout can be in "default" state is if the user has not interacted with it
    setFlyout(isFlyoutVisible ? null : "notifications");
  };

  return (
    <>
      <EuiHeaderSectionItemButton
        onClick={handleToggle}
        notification={totalCount}
        aria-label="Notifications"
        aria-expanded={isFlyoutVisible}
        data-test-subj="notifications-button"
      >
        <EuiIcon type="bell" />
      </EuiHeaderSectionItemButton>
      {isFlyoutVisible && (
        <EuiFlyout
          type={"push"}
          size="340px"
          onClose={() => closeFlyout()}
          aria-labelledby={"homePagePushedFlyout"}
          hideCloseButton={true}
          pushMinBreakpoint="xxl"
        >
          <EuiFlyoutHeader hasBorder>
            <EuiTitle size="s">
              <EuiFlexGroup alignItems="center">
                <EuiFlexItem>
                  <EuiTitle size="xs">
                    <h2>Notifications</h2>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiSwitch
                    label="Only show unread"
                    checked={onlyShowUnread}
                    onChange={(e) => setOnlyShowUnread(e.target.checked)}
                    compressed
                    labelProps={{ style: { fontWeight: "normal" } }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiTitle>
          </EuiFlyoutHeader>

          <EuiFlyoutBody>
            <NotificationsFeedBody
              onlyShowUnread={onlyShowUnread}
              onNotificationRead={(
                notificationId,
                region,
                refetchNotifications,
              ) =>
                void handleNotificationRead(
                  notificationId,
                  region,
                  refetchNotifications,
                )
              }
            />
          </EuiFlyoutBody>
        </EuiFlyout>
      )}
    </>
  );
};
