import { EuiIcon } from "@inscopix/ideas-eui";
import { ColDef, GridReadyEvent, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { EuiBadgeMemo } from "components/ToolParamsGrid/EuiBadgeMemo";
import { UserAvatarRenderer } from "components/UserAvatarRenderer/UserAvatarRenderer";
import { ApplicationUser, Project, Tenant } from "graphql/_Types";
import { ModalProvider } from "providers/ModalProvider/ModalProvider";
import { useCallback, useMemo } from "react";
import { UserAccessLevel } from "types/UserAccessLevels";
import { filesize } from "utils/filesize";
import { formatDate } from "utils/formatDate";
import { isNonNullish } from "utils/isNonNullish";
import { roundToSignificant } from "utils/roundToSignificant";
import { ProjectsGridCellRendererActions } from "./ProjectsGridCellRendererActions";
import { ProjectsGridCellRendererName } from "./ProjectsGridCellRendererName";

export interface ProjectsGridProps {
  projects: (Pick<
    Project,
    | "id"
    | "name"
    | "userId"
    | "dateCreated"
    | "dateLastActive"
    | "usedCredits"
    | "activeStorageSize"
    | "archivedStorageSize"
    | "defaultUserAccessLevel"
    | "key"
  > & {
    user?: {
      email: ApplicationUser["email"];
      username: ApplicationUser["username"];
    };
    role_display: string;
    userPermissions: {
      role: UserAccessLevel["id"];
      role_display: string; //"Admin" | "Owner" | "Viewer" | "Editor";
      download: boolean;
      edit: boolean;
      execute: boolean;
      grant_access: boolean;
      upload: boolean;
      view: boolean;
    };
    tenantKey: Tenant["key"];
  })[];
  onGridReady: (event: GridReadyEvent<ProjectsGridRowDatum>) => void;
}

export type ProjectsGridRowDatum = ProjectsGridProps["projects"][number];

export type ProjectsGridColDef = ColDef<ProjectsGridRowDatum> & {
  // Gets the value that should be written for the cell to a CSV file. If null
  // is specified, the column will be omitted in the CSV.
  csvValueGetter: ((data: ProjectsGridRowDatum) => string) | null;
};

export type ProjectsGridCellRendererParams =
  ICellRendererParams<ProjectsGridRowDatum>;

export const ProjectsGrid = ({ projects, onGridReady }: ProjectsGridProps) => {
  const columnDefs: ProjectsGridColDef[] = useMemo(() => {
    const colDefName: ProjectsGridColDef = {
      colId: "name",
      headerName: "Name",
      field: "name",
      // subscribe to name and role for re renders
      valueGetter: (project) =>
        `${project.data?.name ?? ""}${
          project.data?.userPermissions.role ?? ""
        }`,
      cellRenderer: ProjectsGridCellRendererName,
      flex: 1,
      minWidth: 100,
      csvValueGetter: (data) => data.name,
    };

    const colDefOwner: ProjectsGridColDef = {
      colId: "owner",
      headerName: "Owner",
      valueGetter: (project) => project.data?.userId,
      cellRenderer: ({ data }: ProjectsGridCellRendererParams) => {
        const userId = data?.userId;
        if (userId !== undefined) {
          return <UserAvatarRenderer userId={userId} size="s" />;
        }
        return <EuiIcon type="warning" title="Failed to retrieve user info" />;
      },
      width: 70,
      // User will be undefined if removed from tenant
      csvValueGetter: (data) => data.user?.email ?? "",
    };

    const colDefCreated: ProjectsGridColDef = {
      colId: "created",
      headerName: "Created",
      valueGetter: (project) =>
        project.data?.dateCreated !== undefined
          ? formatDate(project.data.dateCreated)
          : undefined,
      width: 150,
      csvValueGetter: (data) => formatDate(data.dateCreated),
    };

    const colDefUpdated: ProjectsGridColDef = {
      colId: "updated",
      headerName: "Updated",
      valueGetter: (project) => {
        const date = project.data?.dateLastActive;
        if (isNonNullish(date)) {
          return formatDate(date);
        }
      },

      width: 150,
      csvValueGetter: (data) => formatDate(data.dateLastActive),
    };

    const colDefStorage: ProjectsGridColDef = {
      colId: "activeStorage",
      headerName: "Active Storage",
      valueGetter: (project) => Number(project.data?.activeStorageSize ?? "0"),
      valueFormatter: ({ value }: { value: number }) =>
        filesize(value) as string,
      width: 100,
      csvValueGetter: (data) => data.activeStorageSize ?? "0",
    };

    const colDefArchivedStorage: ProjectsGridColDef = {
      colId: "archivedStorage",
      headerName: "Archived Storage",
      valueGetter: (project) =>
        Number(project.data?.archivedStorageSize ?? "0"),
      valueFormatter: ({ value }: { value: number }) =>
        filesize(value) as string,
      width: 100,
      csvValueGetter: (data) => data.archivedStorageSize ?? "0",
    };

    const colDefTotalStorage: ProjectsGridColDef = {
      colId: "totalStorage",
      headerName: "Total Storage",
      valueGetter: (project) =>
        Number(project.data?.archivedStorageSize ?? "0") +
        Number(project.data?.activeStorageSize ?? "0"),
      valueFormatter: ({ value }: { value: number }) =>
        filesize(value) as string,
      width: 100,
      csvValueGetter: (data) => {
        const active = Number(data.activeStorageSize ?? "0");
        const archived = Number(data.archivedStorageSize ?? "0");
        return (active + archived).toString();
      },
    };

    const colDefComputeCredits: ProjectsGridColDef = {
      colId: "computeCredits",
      headerName: "Compute Credits",
      valueGetter: (project) => project.data?.usedCredits ?? 0,
      valueFormatter: ({ value }: { value: number }) =>
        String(roundToSignificant(value)),
      width: 80,
      csvValueGetter: (data) => (data.usedCredits ?? 0).toString(),
    };

    const colDefRole: ProjectsGridColDef = {
      colId: "role",
      headerName: "Access Level",
      valueGetter: (project) => project.data?.role_display ?? "Restricted",
      cellRenderer: ({ data }: ProjectsGridCellRendererParams) => (
        <EuiBadgeMemo color="hollow">
          {data?.role_display ?? "Restricted"}
        </EuiBadgeMemo>
      ),
      width: 120,
      csvValueGetter: (data) => data.role_display,
    };

    const colDefActions: ProjectsGridColDef = {
      colId: "actions",
      cellRenderer: ProjectsGridCellRendererActions,
      width: 40,
      wrapHeaderText: true,
      resizable: false,
      sortable: false,
      pinned: "right",
      csvValueGetter: null,
    };

    return [
      colDefName,
      colDefRole,
      colDefOwner,
      colDefCreated,
      colDefUpdated,
      colDefStorage,
      colDefArchivedStorage,
      colDefTotalStorage,
      colDefComputeCredits,
      colDefActions,
    ];
  }, []);

  const defaultColDef: ProjectsGridColDef = {
    resizable: true,
    sortable: true,
    suppressHeaderMenuButton: true,
    wrapHeaderText: true,
    csvValueGetter: null,
  };

  const rowData = useMemo(() => {
    return projects;
  }, [projects]);

  const getRowId = useCallback(
    ({ data }: { data: ProjectsGridRowDatum }) => data.id,
    [],
  );

  return (
    <ModalProvider>
      <AgGridReact<ProjectsGridRowDatum>
        className="ag-theme-balham-cell-borders"
        getRowId={getRowId}
        headerHeight={45}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowData={rowData}
        rowHeight={35}
        onGridReady={onGridReady}
        // The column definitions used in this grid use custom attributes (e.g.
        // csvValueGetter). By default AG grid throws warnings when you do so.
        suppressPropertyNamesCheck
      />
    </ModalProvider>
  );
};
